const _404page = () => {
    return(
        <div className="_404page">
            <header className="_404page-header">
                <h1>
                    404 Error Not Page.
                </h1>
            </header>
        </div>
    )
}

export default _404page;