import React, {Fragment} from "react";
import { Outlet } from 'react-router-dom';

const Layout = () => {

    return(
        <Fragment>
            <Outlet />
            <p className="footer">© BinzO Platform || Designed By Innenta Solutions</p>
        </Fragment>
    )
}

export default Layout;