import { Routes, Route } from 'react-router-dom';

import Homepage from './pages/homepage';
import AdminPanel from './pages/admin-panel';
import Login from './pages/login';
import _404page from './pages/_404page';
import Layout from './components/layout';

import './App.css';

function App() {
  return (
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<Homepage />}/>
          <Route path='/admin-login' element={<Login />} />
          <Route path='/admin-panel' element={<AdminPanel />} />
          <Route path='*' element={<_404page />} />
        </Route>
      </Routes>
  );
}

export default App;
